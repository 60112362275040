import React from 'react'

const Hero = () => {
  return (
    <div className='hero'>
      <h1 className='head-font'>Quiz <span>Modules</span></h1>
      <p>Hi Job Seeker Take A Quick Test</p>
    </div>
  )
}

export default Hero