import React from 'react'

const JobSearch = () => {
  return (
    <div className='job-search'>
        <h1 className='head-font'>All My <span>Jobs</span></h1>
        <p>Find Your Jobs Listings</p>
    </div>
  )
}

export default JobSearch