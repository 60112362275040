import React, { useState } from 'react'

const Hero = () => {
  return (
    <div className='hero'>
      <h1 className='head-font'>Salary <span>Estimate</span></h1>
      <p>Find The Salary Of Your Dream Job</p>
    </div>
  )
}

export default Hero